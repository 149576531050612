<template>
  <div v-if="page.length">
    <div class="relative">
      <img
        :src="isMobile ? page[0].img_m : page[0].img"
        class="width-100"
        alt=""
      >
      <div
        class="absolute"
        :style="isMobile ? {
          top: '100px',
          left: '20px'
        } : {
          top: '200px',
          // left: `${80/3}vw`
          left: `${30/1.8}vw`
        }"
      >
        <div
          class="dib bd bb bd-000"
          :class="isMobile ? 'f20 pb10 mb10' : 'f40 b pb10 mb10'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          {{ page[0].t1 }}
        </div>
        <div
          :class="isMobile ? 'f16' : 'f24 c-666'"
          style="font-weight:300"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          {{ page[0].t2 }}
        </div>

        <div :style="isMobile ? {
          marginTop:'80px'
        } : 'margin-top: 140px; width: 200px;'"
        >
          <div
            :class="isMobile ? 'f14' : 'f20 c-666'"
            style="font-weight:600; padding-bottom: 5px; margin-bottom: 5px;"
            :style="isMobile ? {
              fontWeight:'normal',
              border:'1px solid #9A9596',
              display:'inline-flex',
              padding: '4px 8px',
              color: '#6C696A'
            } : 'border-bottom: 1px solid #A8BED3;'"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            {{ page[0].t3 }}
          </div>

          <div
            v-if="!isMobile"
            :class="isMobile ? 'f12' : 'f12 c-666'"
            style="font-weight:lighter"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            {{ page[0].t4 }}
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <img
        v-if="!isMobile"
        :src="page[1].img"
        class="width-100"
        alt=""
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
      <div
        :class="isMobile ? 'ptb40 plr20' : 'absolute'"
        :style="{
          top: '120px',
          left: `${30/2.2}vw`
        }"
      >
        <div
          class="bd bb bd-000 pb30 mb30"
          :class="isMobile ? 'f24' : 'f40 dib'"
          v-html="isMobile ? page[1].t1.split(' ').join('<br>') : page[1].t1"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
        />
        <div
          v-for="(item,i) in page[1].t2"
          :key="i"
          class=""
          :class="isMobile ? 'f16 mb10' : 'f20 mb20'"
          :style="i === page[1].t2.length - 1 ? '' : 'font-weight:lighter'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
        >
          {{ item }}
        </div>
      </div>
    </div>

    <div
      class="relative"
      :class="isMobile && 'pb40 pt20'"
      :style="{ backgroundImage:`url(${page[2].img_bg})`, backgroundSize:'100% 100%', backgroundRepeat: 'no-repeat',  }"
    >
      <div
        :class="isMobile ? 'tc pt40' : 'absolute'"
        :style="{
          top: '260px',
          right: `${25}vw`
        }"
      >
        <div
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
          :class="isMobile ? 'f24 mb10' : 'f40 mb30'"
        >
          {{ page[2].t1 }}
        </div>
        <div
          class="mb20"
          :class="isMobile ? 'f16' : 'f20'"
          :style="'font-weight:normal'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        >
          {{ page[2].t2 }}
        </div>
<!--        <div-->
<!--          class="f14 c-999"-->
<!--          v-vue-aos.once="{animationClass:'fadeInUp animated'}"-->
<!--          data-aos="fade-up"-->
<!--        >-->
<!--          {{ page[2].t3 }}-->
<!--        </div>-->
      </div>
      <img
        :src="isMobile ? page[2].img_m : page[2].img"
        :class="isMobile ? 'm-auto db' : ''"
        :style="isMobile ? 'width:80%; margin-top:50px; margin-bottom: -20px;' : ' margin-top:200px; margin-bottom: -20px; margin-left:400px;'"
        alt=""
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
    </div>

    <div
      class="relative tc"
      style="background:#FAFDFF; padding-bottom: 40px;"
    >
      <div :style="isMobile && 'background:#E3F3FE;padding: 30px 0 100px 0;'">
        <div
          :style="isMobile ? 'font-size:24px;' : 'font-size:40px;padding: 80px 0 10px 0;'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        >
          {{ page[3].t1 }}
        </div>
        <div
          class="dib"
          style="width: 50px;height: 3px;background: #ed7989;"
        />
        <div
          :class="isMobile ? 'f18 mt20' : 'f32 mt20'"
          style="font-weight:lighter"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        >
          {{ page[3].t2 }}
        </div>
      </div>
      <div
        :class="isMobile ? '' : 'flex-center mt50 pb50'"
        :style="isMobile && 'margin-top:-70px;'"
      >
        <div
          v-for="(item, i) in page[3].t3"
          :key="i"
          :style="isMobile ? 'height:115px; display:flex; align-items: center;' : 'width:210px;height:270px'"
          class="br5 mlr15"
          :class="isMobile ? 'plr15 mb15 bg-fff tl' : 'pt15'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <img
            :style="{ width: isMobile ? '60px' : '90px' }"
            :src="[site.icon.i1,site.icon.i2,site.icon.i3,site.icon.i4,site.icon.i5,site.icon.i6][i]"
            alt=""
          >
          <div :class="isMobile && 'ml15'">
            <div
              class="f18"
              :class="{
                mt20: !isMobile
              }"
              :style="{
                color: isMobile && '#ed7989'
              }"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              {{ item.t }}
            </div>
            <div
              :style="isMobile ? '' : 'text-align: center;'"
              style="font-weight: lighter"
              class="f16"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              {{ item.d }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isMobile"
      :style="{ height: 'auto', padding: '230px 0',
        backgroundImage:`url(${page[5].img})`,
        backgroundSize:'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }"
    >
      <div style="margin-left: 18%;">
        <div style="font-size: 30px; font-weight: bold; letter-spacing: 3px;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="fade-up"
             data-aos-delay="500">{{ page[5].t1 }}</div>
        <div style="font-size: 17px; letter-spacing: 1px; margin: 10px 0;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="fade-up"
             data-aos-delay="1000"
        >{{ page[5].t2 }}</div>
        <div style="display: flex; align-items:center; margin-top: 40px;">
          <div style="border: 1px solid #333333; padding: 12px 20px; margin-right: 20px; display: flex; max-width: 350px; align-items:center;"
               v-vue-aos.once="{animationClass:'fadeInUp animated'}"
               data-aos="zoom-in"
               data-aos-duration="2000"
          >
            <img :src="page[5].items[0].icon" style="width: 35px; height: 35px; margin-right: 15px;">
            <div>
              <div style="font-weight: bold; font-size: 24px;">{{page[5].items[0].t}}</div>
              <div style="font-size: 16px; line-height: 22px; margin-top: 5px;">{{page[5].items[0].d}}</div>
            </div>
          </div>
          <div style="border: 1px solid #333333; padding: 12px 20px; margin-right: 20px; display: flex; max-width: 350px; align-items:center;"
               v-vue-aos.once="{animationClass:'fadeInUp animated'}"
               data-aos="zoom-in"
               data-aos-duration="2000">
            <img :src="page[5].items[1].icon" style="width: 35px; height: 35px; margin-right: 15px;">
            <div>
              <div style="font-weight: bold; font-size: 24px;">{{page[5].items[1].t}}</div>
              <div style="font-size: 16px; line-height: 22px; margin-top: 5px;">{{page[5].items[1].d}}</div>
            </div>
          </div>
          <div style="border: 1px solid #333333; padding: 12px 20px; display: flex; max-width: 350px; align-items:center;"
               v-vue-aos.once="{animationClass:'fadeInUp animated'}"
               data-aos="zoom-in"
               data-aos-duration="2000">
            <img :src="page[5].items[2].icon" style="width: 35px; height: 35px; margin-right: 15px;">
            <div>
              <div style="font-weight: bold; font-size: 24px;">{{page[5].items[2].t}}</div>
              <div style="font-size: 16px; line-height: 22px; margin-top: 5px;" v-html="page[5].items[2].d"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else
         :style="{ height: 'auto', padding: '70px 0 350px 0',
        backgroundImage:`url(${page[5].img_m})`,
        backgroundSize:'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }"
    >
      <div style="text-align: center; font-size: 26px; font-weight: bold; letter-spacing: 1px;"
           v-vue-aos.once="{animationClass:'fadeInUp animated'}"
           data-aos="fade-up"
           data-aos-delay="500"
      >{{ page[5].t1 }}</div>
      <div style="text-align: center; font-size: 15px; letter-spacing: 1px; margin: 5px 0 0 0;"
           v-vue-aos.once="{animationClass:'fadeInUp animated'}"
           data-aos="fade-up"
           data-aos-delay="1000"
      >{{ page[5].t2 }}</div>
      <div style="width: 75%; margin: 40px auto 0 auto">
        <div style="border: 1px solid #666; padding: 5px 20px 10px 20px; margin-right: 20px; display: flex; width: 100%; margin-bottom: 10px;  align-items:center;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="zoom-in"
             data-aos-duration="2000"
        >
          <img :src="page[5].items[0].icon" style="width: 35px; height: 35px; margin-right: 15px;">
          <div>
            <div style="font-weight: bold; font-size: 20px;">{{page[5].items[0].t}}</div>
            <div style="font-size: 15px; line-height: 20px; margin-top: 5px;">{{page[5].items[0].d}}</div>
          </div>
        </div>
        <div style="border: 1px solid #666; padding: 5px 20px 10px 20px; margin-right: 20px; width: 100%;  margin-bottom: 10px; display: flex; align-items:center;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="zoom-in"
             data-aos-duration="3000">
          <img :src="page[5].items[1].icon" style="width: 35px; height: 35px; margin-right: 15px;">
          <div>
            <div style="font-weight: bold; font-size: 20px;">{{page[5].items[1].t}}</div>
            <div style="font-size: 15px; line-height: 20px; margin-top: 5px;">{{page[5].items[1].d}}</div>
          </div>
        </div>
        <div style="border: 1px solid #666; padding: 5px 20px 10px 20px; display: flex; width: 100%;  margin-bottom: 10px; align-items:center;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="zoom-in"
             data-aos-duration="4000">
          <img :src="page[5].items[2].icon" style="width: 35px; height: 35px; margin-right: 15px;">
          <div>
            <div style="font-weight: bold; font-size: 20px;">{{page[5].items[2].t}}</div>
            <div style="font-size: 15px; line-height: 20px; margin-top: 5px;">{{page[5].items[2].d}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: []
    }
  },

  async created () {
    const { data } = await this.fetch('/chanpin.json')
    this.page = data
  },

  methods: {
  }
}
</script>
